<template>
  <div class="overflow-y-auto overflow-x-hidden">
    <feedback-form title-border-radius="0" @close="onClose" />
  </div>
</template>

<script>
import FeedbackForm from '@/components/other/FeedbackForm.vue'
import IframeConnection from '@/plugins/classes/IframeConnection'
let iConnection = null;

export default {
  name: 'Feedback',
  components: {
    FeedbackForm
  },
  mounted () {
    iConnection = new IframeConnection(window.parent);
  },
  methods: {
    onClose () {
      iConnection.postMessage('feedback-form:close');
    }
  }
}
</script>